//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import VeLine from "v-charts/lib/line.common";
import countTo from "vue-count-to";
import { fetchDashEqu, fetchDashOrder, fetchDashTrade } from "@/api/sys";
export default {
  components: {
    VeLine: VeLine,
    countTo: countTo
  },
  data: function data() {
    return {
      chartData: {
        columns: ["日期", "金额", "成交量"],
        rows: []
      },
      equOptions: [{
        bg: "bg-purple",
        text: "在线设备",
        icon: "restart",
        val: 0,
        label: "online"
      }, {
        bg: "bg-green",
        text: "离线设备",
        icon: "Off-line",
        val: 0,
        label: "offline"
      }, {
        bg: "bg-blue",
        text: "运营设备总数",
        icon: "Sellout",
        val: 0,
        label: "operate"
      }, {
        bg: "bg-orage",
        text: "设备总数",
        icon: "restart",
        val: 0,
        label: "total"
      }],
      orderOps: {
        count: 0,
        money: 0,
        avg: 0,
        charging_avg: 0
      },
      dayTab: "1"
    };
  },
  created: function created() {
    this.getEquList();
    this.getOrderList();
    this.getTradeList();
  },
  methods: {
    getEquList: function getEquList() {
      var _this = this;

      fetchDashEqu().then(function (res) {
        var data = res.data;

        _this.transEqu(data);
      });
    },
    getOrderList: function getOrderList() {
      var _this2 = this;

      fetchDashOrder(this.dayTab).then(function (res) {
        _this2.orderOps = res.data;
      }).catch(function (res) {});
    },
    getTradeList: function getTradeList() {
      var _this3 = this;

      fetchDashTrade().then(function (res) {
        var data = res.data;
        console.log(data);

        _this3.transTrade(data);
      }).catch(function (err) {
        console.log(err);
      });
    },
    transTrade: function transTrade(data) {
      var arr = [];

      for (var i = 0; i < data.length; i++) {
        var obj = {};
        obj["日期"] = data[i]["date"];
        obj["金额"] = data[i]["money"];
        obj["成交量"] = data[i]["quantity"];
        arr.push(obj);
      }

      this.chartData.rows = arr;
    },
    transEqu: function transEqu(data) {
      console.log(data);
      var equOps = this.equOptions;

      for (var i in data) {
        for (var j in equOps) {
          if (i == equOps[j]["label"]) {
            equOps[j]["val"] = data[i];

            if (equOps[j]["label"] == 'online') {
              equOps[j]["rate"] = data['online_rate'];
            }
          }
        }
      }
    },
    handleChange: function handleChange() {
      this.getOrderList();
    }
  }
};