import request from '@/utils/request';
/**
 *  fetch: 获取
 *  update: 修改
 *  add: 增加
 *  del: 删除
 */
// 管理员列表

export function fetchAdminList(data) {
  return request({
    url: '/users',
    method: 'get',
    params: data
  });
} // 删除管理员

export function delAdminer(data) {
  return request({
    url: '/users',
    method: 'delete',
    data: data
  });
} // 添加管理员

export function addAdminer(data) {
  return request({
    url: '/users',
    method: 'post',
    data: data
  });
} // 编辑管理员

export function updateAdminer(data) {
  return request({
    url: "/users/".concat(data.id),
    method: 'put',
    data: data
  });
} // 角色列表

export function fetchRoleList(data) {
  return request({
    url: '/roles',
    method: 'get',
    params: data
  });
} // 添加角色

export function addRole(data) {
  return request({
    url: '/roles',
    method: 'post',
    data: data
  });
} // 编辑角色

export function updateRole(data) {
  return request({
    url: '/roles/' + data.id,
    method: 'put',
    data: data
  });
} // 删除角色

export function delRole(id) {
  return request({
    url: '/roles/' + id,
    method: 'delete'
  });
} // 所有权限

export function fetchPermission() {
  return request({
    url: '/permissions',
    method: 'get'
  });
} // 更新角色权限

export function updateRolePer(data) {
  return request({
    url: '/permissions',
    method: 'post',
    data: data
  });
} // 首页
// 设备总数

export function fetchDashEqu() {
  return request({
    url: '/device_info',
    method: 'get'
  });
} // 订单概述

export function fetchDashOrder(type) {
  return request({
    url: '/summary',
    method: 'get',
    params: {
      type: type
    }
  });
} // 7天交易趋势

export function fetchDashTrade() {
  return request({
    url: '/trend7',
    method: 'get'
  });
} // 销售额统计

export function fetchSales() {
  return request({
    url: '/sales',
    method: 'get'
  });
} // 待处理统计

export function fetchTodos() {
  return request({
    url: '/todos',
    method: 'get'
  });
} // 订单人数统计

export function fetchOrderPerson() {
  return request({
    url: '/order_statics',
    method: 'get'
  });
} // 门店销售额

export function fetchRank(type) {
  return request({
    url: '/rank',
    method: 'get',
    params: {
      type: type
    }
  });
} // 营业额趋势

export function fetchTurnover(type) {
  return request({
    url: '/turnover',
    method: 'get',
    params: {
      type: type
    }
  });
}